import { Trans } from "@lingui/macro"
import { Typography, Box, Divider } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"

import ManageLootbox from "../../components/modules/lootBoxes/ManageLootbox"
import { useLootbox } from "../../contexts/LootboxContext"

interface IProps {
  isConfigure?: boolean
}

const LootboxManagePage: React.FC<IProps> = ({ isConfigure }) => {
  const { setLootboxToManage, lootboxes } = useLootbox()
  const { lootboxAddress } = useParams()

  const lootbox = useMemo(
    () => lootboxes?.find((l) => l.address === lootboxAddress),
    [lootboxAddress, lootboxes]
  )

  useEffect(() => {
    setLootboxToManage(lootbox)
    return () => {
      setLootboxToManage(undefined)
    }
  }, [lootbox, setLootboxToManage])

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" flexDirection="column" mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>
              {isConfigure ? "Configure" : "Manage"} {lootbox?.name || "Lootbox"}
            </Trans>
          </Typography>
          <Typography variant="body1" mt={0.5}>
            <span style={{ color: "#8C8C8C" }}>
              <Trans>LootBox address : </Trans>
            </span>
            <span style={{ color: "#DFF7C7", marginLeft: "8px" }}>{lootboxAddress}</span>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <ManageLootbox isConfigure={isConfigure} />
      </Box>
    </Box>
  )
}

export default LootboxManagePage
