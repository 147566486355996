import { Trans } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"

import type { Marketplace } from "../../../contexts/MarketplaceContext"
import { useMarketplace } from "../../../contexts/MarketplaceContext"
import { useWeb3Connection } from "../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../routes/routes"
import { centerEllipsis, getIPFSUri } from "../../../utils/Helpers"
import { getNetwork } from "../../../utils/NetworkHelpers"

interface IProps {
  marketplace: Marketplace
}

const MarketplaceCardComp: React.FC<IProps> = ({ marketplace }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { marketplaceProject } = useMarketplace()
  const { networks } = useWeb3Connection()

  const { name, banner, contract_address, chain_id } = marketplace

  if (!marketplaceProject) return null

  return (
    <Box
      width="100%"
      sx={{ p: 1, borderRadius: "12px", border: "1px solid #262626", background: "#141414" }}
    >
      <Box height={180} display="flex" alignItems="center" mt={1.25} mb={1.25}>
        {banner ? (
          <img
            style={{ width: "100%", maxHeight: 200, borderRadius: "8px" }}
            src={getIPFSUri(banner)}
            alt="marketplace"
            data-cy="label-marketplace-card-cover-image"
          />
        ) : (
          <Box height="180px" width="100%" sx={{ background: "#595959", borderRadius: "8px" }} />
        )}
      </Box>
      <Box p={1} display="flex" justifyContent="space-between">
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="18px" data-cy="label-marketplace-card-name">
              {name}
            </Typography>
          </Box>
          <Box>
            {contract_address ? (
              <Box display="flex" gap={1} alignItems="center">
                <Typography color="#8C8C8C" data-cy="label-marketplace-card-address">
                  {centerEllipsis(contract_address, 8)}
                </Typography>
                <ContentCopy
                  color="primary"
                  sx={{ cursor: "pointer", fontSize: 16 }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    enqueueSnackbar("Marketplace address copied", { variant: "success" })
                    navigator.clipboard.writeText(contract_address)
                  }}
                  data-cy="button-marketplace-card-copy-address"
                />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography data-cy="label-marketplace-card-not-on-chain" color="#FFE58F">
                  <Trans>Not on-chain</Trans>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography
            fontSize="14px"
            color="#8C8C8C"
            sx={{ pt: 0.5 }}
            data-cy="label-marketplace-card-network"
          >
            {getNetwork(chain_id, networks)?.label || "unknown"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const MarketplaceCard: React.FC<IProps> = ({ marketplace }) => {
  const { marketplaceProject } = useMarketplace()

  const { contract_address } = marketplace
  const hasSynced = useMemo(() => !!contract_address, [contract_address])

  if (!marketplaceProject) return null

  return hasSynced ? (
    <Link
      to={ROUTE_LINKS.marketplaceView(marketplaceProject.projectId, marketplace.id)}
      style={{ textDecoration: "none" }}
      data-cy="link-marketplaces-card-go-to-listed-nfts"
    >
      <MarketplaceCardComp marketplace={marketplace} />
    </Link>
  ) : (
    <MarketplaceCardComp marketplace={marketplace} />
  )
}

export default MarketplaceCard
