import React from "react"

import { useLootbox } from "../../../../contexts/LootboxContext"

import ManageOpenLootbox from "./ManageOpenLootbox"

interface IProps {
  isConfigure?: boolean
}

const OpenLootboxModule: React.FC<IProps> = () => {
  const { lootboxToOpen } = useLootbox()

  if (!lootboxToOpen) {
    return null
  }

  return <ManageOpenLootbox lootboxToOpen={lootboxToOpen} />
}

export default OpenLootboxModule
